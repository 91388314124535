import React from "react";

import MainBody from '../components/MainBody';

const Home = () => {
    return (
        <div>
            <MainBody />
        </div>
    );
};

export default Home;
